import React, {SVGAttributes} from 'react'

const Chevron: React.FC<SVGAttributes<SVGElement>> = (props) => (
  <svg width={6} height={10} viewBox="0 0 6 10" {...props}>
    <path
      d="M5.907 4.8L.55.084a.352.352 0 0 0-.454 0 .259.259 0 0 0 0 .4L5.225 5 .094 9.516a.259.259 0 0 0 0 .4.347.347 0 0 0 .452 0L5.905 5.2a.258.258 0 0 0 .002-.398z"
      fill="#FFF"
    />
  </svg>
)

export default Chevron
