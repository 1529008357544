import {BikiniEvent, AlgoliaRecord} from '../types/bikini'
import algoliasearch from 'algoliasearch'

import React, {useState, Dispatch, SetStateAction, ReactNode, useEffect, useRef} from 'react'
import {graphql} from 'gatsby'
import styled, {ThemeProvider} from 'styled-components'

import Layout from '../layout'
import Banner from '../components/banner'
import Filters, {FilterMode, FilterType, FilterTag} from '../components/filters'
import {Title} from '../components/styled'
import Event from '../components/event'
import EventLine from '../components/eventline'
import Loader from '../images/loader'

import {eventFilter, yymm, month, currentTimestamp} from '../helpers/date'
import algolia from '../helpers/algolia'
import {queryMatches} from '../helpers/text'
import {MOBILE_BREAKPOINT} from '../helpers/constants'
import {isBrowser} from '../helpers/window'
import {useClientKey} from '../helpers/hooks'

const MODE_STORAGE = 'eventsDisplayMode'
const SCROLL_TRIGGER = 1000 // pixels from scrolling to bottom
const EVENT_PAGING = 10
const LINE_PAGING = 20

const Events = styled.div`
  ${(props) => (props.theme.squares ? 'display: flex; flex-wrap: wrap; margin: -10px;' : '')}
`

interface Refs {
  time: number
  ready: boolean
  params: object // identifier to match response with current request
  hits: AlgoliaRecord[]
  page: number
  pages: number
  type: FilterType
  tag: FilterTag
  mode: FilterMode
  limit: number
  listLength: number
}
interface Month {
  id: string
  title: string
  events: BikiniEvent[]
}

const ProgrammationPage = ({data}) => {
  const pageState = (isBrowser() && history.state) || {}

  const refs = useRef<Refs>({
    time: currentTimestamp(), // so that it does not change during scrolling
    ready: true,
    params: null, // to match requests with responses
    // because fetchMore() is triggered from a scroll event, thus has no access to updated state:
    hits: [],
    page: 0,
    pages: null,
    type: pageState.type || null,
    tag: pageState.tag || null,
    mode: pageState.mode || FilterMode.list,
    limit: pageState.limit || EVENT_PAGING,
    listLength: 0,
  })

  const getEvents = (): BikiniEvent[] => {
    return data.allBikiniEvent.edges
      .map(({node}) => node)
      .filter((event: BikiniEvent) => {
        if (!eventFilter(event)) {
          return false // TODO: live unveil
        }
        if (refs.current.type && event.clubbing !== (refs.current.type === FilterType.club)) {
          return false
        }
        if (refs.current.tag && !event._tags.includes(refs.current.tag)) {
          return false
        }
        return true
      })
      .slice(0, refs.current.limit)
  }

  // future events scroll paging
  const [eventList, setEvents] = useState(getEvents)

  // filters
  const [type, setType] = useState<FilterType>(refs.current.type)
  const [tag, setTag] = useState<FilterTag>(refs.current.tag)
  const [mode, setMode] = useState<FilterMode>(refs.current.mode)

  // history, loaded using Algolia
  const [loading, setLoading]: [boolean, Dispatch<SetStateAction<boolean>>] = useState(false)
  const [hits, setHits]: [AlgoliaRecord[], Dispatch<SetStateAction<AlgoliaRecord[]>>] = useState([])

  const setTypeAndReset = (newType: FilterType) => {
    if (newType === type) {
      return
    }
    refs.current.type = newType
    fetchMore(true)
    setType(newType)
    history.replaceState({...history.state, type: newType}, '')
  }
  const setTagAndReset = (newTag: FilterTag) => {
    if (newTag === tag) {
      return
    }
    refs.current.tag = newTag
    fetchMore(true)
    setTag(newTag)
    history.replaceState({...history.state, tag: newTag}, '')
  }

  const setModeAndStore = (newMode: FilterMode) => {
    if (newMode === mode) {
      return
    }
    refs.current.mode = newMode
    fetchMore(true)
    setMode(newMode)
    history.replaceState({...history.state, mode: newMode}, '')

    if (newMode !== FilterMode.history) {
      try {
        localStorage.setItem(MODE_STORAGE, newMode)
      } catch (e) {
        // ignore
      }
    }
  }

  const fetchMore = (reset?: boolean) => {
    if (refs.current.mode !== FilterMode.history) {
      refs.current.ready = false
      if (reset) {
        refs.current.limit = EVENT_PAGING
      } else {
        refs.current.limit += EVENT_PAGING
      }
      const events = getEvents()
      if (reset || refs.current.listLength < events.length) {
        setEvents(events)
        refs.current.listLength = events.length
      }
      history.replaceState({...history.state, limit: refs.current.limit}, '')
      return
    }

    if (reset) {
      refs.current.hits = []
      refs.current.page = 0
      refs.current.pages = null
      setHits([])
    }

    if (refs.current.pages === refs.current.page) {
      // no need to fetch more here because the last retrieved page wasn't full so it means it was the last one
      return
    }

    const filters: string[] = [`date < ${refs.current.time}`]

    if (refs.current.type) {
      filters.push(`clubbing:${refs.current.type === FilterType.club}`)
    }
    if (refs.current.tag) {
      filters.push(refs.current.tag)
    }

    refs.current.params = {
      filters: filters.join(' AND '),
      hitsPerPage: LINE_PAGING,
      page: refs.current.page,
    }

    refs.current.ready = false // for internal check (synchronous)
    setLoading(true) // for UI (asynchronous)

    algolia.search(refs.current.params, handleResults)
  }

  const handleResults = (error: Error, response: algoliasearch.Response) => {
    if (error) {
      throw error
    }

    if (!queryMatches(response.params, refs.current.params)) {
      // outdated response: there was a newer query made
      return
    }

    refs.current.hits = refs.current.hits.concat(response.hits)
    refs.current.page = response.page + 1
    refs.current.pages = response.nbPages
    setHits(refs.current.hits)
    setLoading(false)
    refs.current.ready = true
  }

  useEffect(() => {
    refs.current.ready = true
  }, [eventList])

  useEffect(() => {
    const handleScroll = () => {
      // inspired by https://github.com/CassetteRocks/react-infinite-scroller/blob/master/src/InfiniteScroll.js
      const doc = (document.documentElement || document.body.parentNode || document.body) as HTMLElement
      const scrollTop = window.pageYOffset || doc.scrollTop
      const distanceFromBottom = doc.scrollHeight - (window.innerHeight + scrollTop)
      if (distanceFromBottom < SCROLL_TRIGGER && refs.current.ready) {
        fetchMore()
      }
    }

    window.addEventListener('scroll', handleScroll, {passive: true}) // Mouse wheel issues? => https://stackoverflow.com/a/47684257/1052033

    if (refs.current.mode === FilterMode.history) {
      fetchMore()
    } else if (window.innerWidth > MOBILE_BREAKPOINT) {
      try {
        // get from localStorage once loaded, otherwise the SSR hydration fails when done in useState()
        const storedMode = localStorage.getItem(MODE_STORAGE) as FilterMode
        if (storedMode) {
          setMode(storedMode)
        }
      } catch (e) {
        // ignore
      }
    }

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const key = useClientKey()

  let content: ReactNode

  if (mode === FilterMode.history) {
    content = (
      <>
        <Title>Événements passés</Title>
        {hits.map((event) => (
          <EventLine key={event.objectID} event={event} past />
        ))}
        {loading && <Loader />}
      </>
    )
  } else {
    const months: Month[] = []

    let previousYearMonth: string
    eventList.forEach((event) => {
      const currentYearMonth = yymm(event.date)
      if (currentYearMonth !== previousYearMonth) {
        months.push({
          id: yymm(event.date),
          title: month(event.date),
          events: [],
        })
      }
      months[months.length - 1].events.push(event)
      previousYearMonth = currentYearMonth
    })

    content = (
      <ThemeProvider theme={{squares: mode === FilterMode.squares}}>
        <>
          {months.map(({id, title, events}) => (
            <div key={id}>
              <Title>{title}</Title>
              <Events key={key(mode)}>
                {events.map((event) => (
                  <Event key={event.id} event={event} />
                ))}
              </Events>
            </div>
          ))}
        </>
      </ThemeProvider>
    )
  }

  return (
    <Layout title="Programmation">
      <Banner />
      <Filters
        type={type}
        setType={setTypeAndReset}
        tag={tag}
        setTag={setTagAndReset}
        mode={mode}
        setMode={setModeAndStore}
      />
      {content}
    </Layout>
  )
}

export default ProgrammationPage

export const query = graphql`
  query {
    allBikiniEvent(filter: {past: {eq: false}}, sort: {fields: [date], order: ASC}) {
      edges {
        node {
          id
          path
          date
          end
          unveilDate
          title
          clubbing
          style
          image {
            childImageSharp {
              fluid(maxWidth: 320) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          imagePosition
          place {
            name
          }
          _tags
          startPrice
          free
          status
          ticketUrl
          password
          passwordLimitDate
          passwordPrompt
          adultsOnly
        }
      }
    }
  }
`
