import {BikiniEvent, AlgoliaRecord} from '../types/bikini'

import React from 'react'
import {Link} from 'gatsby'

import styled, {css} from 'styled-components'

import Img from './image'
import Tags from './tags'
import BookButton from './bookbutton'

import {colors, MOBILE_BREAKPOINT} from '../helpers/constants'
import {ellipsis} from '../helpers/style'
import {dayMonthYear, timeFormat, timeLabel} from '../helpers/date'
import {available, getSubtitle, getStartPrice} from '../helpers/text'
import {useClientKey} from '../helpers/hooks'

const Container = styled.div`
  position: relative;
  background: ${colors.white};
  ${(props) =>
    props.theme.squares
      ? 'display: flex; flex-direction: column; width: 320px; height: 460px; margin: 10px'
      : 'margin: 25px 0'};
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 10px 0;
  }
`

const EventLink = styled(Link)`
  ${(props) => (props.theme.squares ? 'flex: 1' : 'overflow: hidden; display: flex')};
`
const ImageContainer = styled.div`
  position: relative;
  ${(props) => (props.theme.squares ? 'overflow: hidden' : 'width: 25vw')};
  max-width: ${(props) => (props.theme.squares ? 320 : 247)}px;
  height: ${(props) => (props.theme.squares ? 220 : 170)}px;
  background: ${colors.lightGrey};
  /* because no IE11 support for object-fit on child image: */
  display: flex;
  align-items: center;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    position: absolute;
    top: 38px;
    left: 10px;
    width: 65px;
    height: auto;
  }
`
const Image = styled(Img)`
  width: 100%;
`
const Place = styled.div`
  position: absolute;
  right: 15px;
  bottom: 13px;
  text-align: right;
  background: ${colors.tags.other};
  padding: 5px 14px;
  font-weight: 600;
  font-size: 0.85rem;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    display: none;
  }
`

const Content = styled.div`
  flex: 1;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  min-width: 270px;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    min-width: 200px;
  }
`
const EventHead = styled.div`
  font-size: 1.1rem;
  padding: ${(props) => (props.theme.squares ? 10 : 7)}px 20px;
  border-bottom: 3px solid ${colors.background};
  white-space: nowrap;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 3px 10px;
  }
`
const EventDate = styled.span`
  font-weight: 600;
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 20px);
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 0.9rem;
  }
`
const Infos = styled.div`
  padding: 13px 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: 1.2rem;
  font-size: 0.9rem;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 7px 10px;
    margin: 0 0 5px 75px;
    font-size: 0.75rem;
    line-height: 1rem;
  }
`

const Title = styled.div`
  font-weight: bold;
  font-size: 1.1rem;
  ${(props) => (props.theme.squares ? 'max-height: 3.6rem; overflow: hidden;' /* max 3 lines */ : '')}
  em {
    font-style: inherit;
    background: ${colors.lightGrey};
  }
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 0.8rem;
  }
`
const Style = styled.div`
  margin: 2px 0 5px;
  ${ellipsis}
  em {
    font-style: inherit;
    background: ${colors.lightGrey};
  }
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 0;
  }
`

const Buttons = styled.div`
  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    ${(props) =>
      props.theme.squares
        ? 'align-self: center; margin-bottom: 25px'
        : 'position: absolute; bottom: 50px; right: 45px'};
  }
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-left: 85px;
    padding-bottom: 15px;
  }
`
const buttonStyle = css`
  border-radius: 20px;
  font-size: 0.75rem;
  font-weight: 600;
  padding: 5px 16px;
  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    padding: 9px 20px;
    font-size: 0.85rem;
  }
`
const TicketButton = styled(BookButton)<{available?: boolean}>`
  ${buttonStyle}
  background: ${(props) => (props.available ? colors.primary : colors.red)};
  color: ${(props) => (props.available ? colors.white : colors.primary)};
`
const AdultsOnly = styled.span`
  ${buttonStyle}
  color: ${colors.red};
  background: ${colors.primary};
  position: absolute;
  right: 15px;
  pointer-events: none;
  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    ${(props) => (props.theme.squares ? 'top: 13px' : 'position: initial; margin-right: 20px')};
  }
`

interface Props {
  event: BikiniEvent | AlgoliaRecord
}

const Event: React.FC<Props> = ({event}) => {
  let title: JSX.Element
  let style: JSX.Element
  const highlights = (event as AlgoliaRecord)._highlightResult
  if (highlights) {
    const infos = []
    if (highlights.style.value) {
      infos.push(highlights.style.value)
    }
    if (event.place) {
      infos.push((event as AlgoliaRecord).place)
    }
    title = <Title dangerouslySetInnerHTML={{__html: highlights.title.value}} />
    style = <Style dangerouslySetInnerHTML={{__html: infos.join(', ')}} />
  } else {
    title = <Title>{event.title}</Title>
    style = <Style>{getSubtitle(event as BikiniEvent)}</Style>
  }

  const {path, imagePosition, image, _tags, adultsOnly, date, end} = event

  const key = useClientKey()

  return (
    <Container key={key((event as BikiniEvent).id || (event as AlgoliaRecord).objectID)}>
      <EventLink to={path}>
        <ImageContainer style={{alignItems: imagePosition}}>
          <Image src={image} />
          {_tags.includes('other') && (
            <Place>{(event as BikiniEvent).place.name || (event as AlgoliaRecord).place}</Place>
          )}
        </ImageContainer>
        <Content>
          <EventHead>
            <Tags tags={_tags} />
            <EventDate>{dayMonthYear(date, end)}</EventDate>
          </EventHead>
          <Infos>
            <div>
              {title}
              {style}
            </div>
            <div>
              <div>{getStartPrice(event)}</div>
              <div>
                {timeLabel(_tags)}
                {timeFormat(date)}
              </div>
            </div>
          </Infos>
        </Content>
      </EventLink>
      <Buttons>
        {adultsOnly && <AdultsOnly>+18 ans</AdultsOnly>}
        <TicketButton available={available(event)} event={event} />
      </Buttons>
    </Container>
  )
}

export default React.memo(Event)
