import {BikiniEvent, AlgoliaRecord} from '../types/bikini'

import React from 'react'
import {Link} from 'gatsby'
import styled from 'styled-components'

import BookButton from './bookbutton'
import {available, ellipsis} from '../helpers/text'
import {dayMonthShort, timeFormat} from '../helpers/date'
import {colors, MOBILE_BREAKPOINT} from '../helpers/constants'
import {ellipsis as cssEllipsis, clamp} from '../helpers/style'
import {useClientKey} from '../helpers/hooks'

const Event = styled.div`
  border-bottom: 2px solid ${colors.secondary};
  display: flex;
  align-items: center;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    align-items: flex-start;
  }
`
const Left = styled(Link)`
  flex: 1;
  padding: 17px 0;
  display: flex;
  align-items: center;
  overflow: hidden;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column;
    padding: 10px 0;
    align-items: flex-start;
  }
`
const Right = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column-reverse;
    align-items: flex-end;
    padding: 10px 0;
  }
`
const EventDate = styled.span`
  display: inline-block;
  width: 180px;
  color: ${colors.secondary};
  font-weight: bold;
`
const EventTitle = styled.span`
  display: inline-block;
  flex: 1;
  color: ${colors.primary};
  font-weight: bold;
  margin-right: 20px;
  ${cssEllipsis}
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    white-space: normal;
    margin-right: 10px;
    margin-top: 5px;
    ${clamp(3)}
  }
`
const EventStatus = styled(BookButton)<{available: boolean}>`
  display: inline-block;
  color: ${(props) => (props.available ? colors.secondary : colors.red)};
  padding: 5px 0 0;
  text-align: right;
  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    padding: 17px 0;
    text-align: center;
    width: 120px;
    white-space: nowrap;
  }
`
const EventTime = styled.span`
  display: inline-block;
  width: 100px;
  text-align: right;
  color: ${colors.secondary};
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 80px;
  }
`

interface Props {
  event: BikiniEvent | AlgoliaRecord
  past?: boolean
}

const EventLine: React.FC<Props> = ({event, past}) => {
  const {path, date, end, title} = event
  const key = useClientKey()

  return (
    <Event key={key((event as BikiniEvent).id || (event as AlgoliaRecord).objectID)}>
      <Left to={path}>
        <EventDate>{dayMonthShort(date, end)}</EventDate>
        <EventTitle>{ellipsis(title, 90)}</EventTitle>
      </Left>
      <Right>
        {past ? null : <EventStatus available={available(event)} event={event} />}
        <EventTime>{timeFormat(date, true)}</EventTime>
      </Right>
    </Event>
  )
}

export default React.memo(EventLine)
