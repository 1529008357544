import {BikiniEventTag} from '../types/bikini'

import React, {useEffect, useState, useRef} from 'react'
import styled from 'styled-components'

import Chevron from '../images/chevron'
import List from '../images/list'
import Squares from '../images/squares'
import History from '../images/history'
import Down from '../images/down'

import {colors, MOBILE_BREAKPOINT} from '../helpers/constants'
import {getScroll} from '../helpers/window'
import {DESKTOP_HEIGHT, MOBILE_HEIGHT, getHeight} from '../layout/header'
import {useClientKey} from '../helpers/hooks'

const BREAKPOINT = 900

const Placeholder = styled.div`
  height: 70px;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    height: 40px;
  }
`

const Container = styled.div<{fixed: boolean}>`
  position: ${(props) => (props.fixed ? 'fixed' : 'initial')};
  z-index: 2;
  top: ${DESKTOP_HEIGHT}px;

  @media (min-width: ${BREAKPOINT}px) {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5), 0 2px 4px rgba(0, 0, 0, 0.5);
    ${(props) => (props.fixed ? 'width: 1000px; max-width: calc(100% - 40px);' : '')}
  }

  @media (max-width: ${BREAKPOINT}px) {
    ${(props) => (props.fixed ? 'width: calc(100% - 40px);' : '')}
  }
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    ${(props) => (props.fixed ? 'width: 100%;' : '')}
    margin: 0 -10px;
    top: ${MOBILE_HEIGHT}px;
  }
`

const Content = styled.div<{open: boolean}>`
  background: ${colors.primary};
  color: ${colors.white};
  z-index: 3;

  @media (min-width: ${BREAKPOINT}px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 50px;
  }

  @media (max-width: ${BREAKPOINT}px) {
    position: absolute;
    top: initial;
    transition: ${(props) => (props.open ? 'height 0.3s' : 'none')};
    height: ${(props) => (props.open ? 165 : 0)}px;
    overflow: hidden;
    padding: 0 10px;
    width: calc(100% - 60px);
  }
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: calc(100% - 20px);
  }
`

const Dropdown = styled.div<{open: boolean}>`
  background: ${colors.primary};
  color: ${colors.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 -10px;
  padding: 0 10px;
  svg.down {
    transition: ${(props) => (props.open ? 'transform 0.3s' : 'none')};
    transform: ${(props) => (props.open ? 'rotate(180deg)' : 'rotate(0deg)')};
    stroke: ${colors.white};
    padding: 0 8px;
    vertical-align: middle;
  }
  svg.history {
    fill: ${colors.grey};
    vertical-align: middle;
  }
  svg.active {
    fill: ${colors.white};
  }
  @media (min-width: ${MOBILE_BREAKPOINT}px) {
    margin: 0;
    width: calc(100% - 40px);
    padding: 12px 20px;
  }
  @media (min-width: ${BREAKPOINT}px) {
    display: none;
  }
`
const TitleButton = styled.button`
  font-size: 1.25rem;
  color: ${colors.white};
  padding: 8px 10px;
`
const HistoryButton = styled.button`
  padding: 8px 10px;
`

const Types = styled.div`
  svg {
    margin: 0 5px 0 10px;
  }
  @media (max-width: ${BREAKPOINT}px) {
    margin: 2px 0 10px;
  }
`
const Prompt = styled.span`
  font-weight: 600;
  @media (max-width: ${BREAKPOINT}px) {
    display: none;
  }
`
const Type = styled.button<{active: boolean}>`
  color: ${(props) => (props.active ? colors.white : colors.grey)};
  @media (max-width: ${BREAKPOINT}px) {
    display: block;
    padding: 5px 50px 5px 0;
  }
`

const Places = styled.div``
const Place = styled.button<{active: boolean}>`
  padding: 0 20px;
  color: ${(props) => (props.active ? colors.white : colors.grey)};
  font-weight: 600;
  @media (max-width: ${BREAKPOINT}px) {
    display: block;
    padding: 5px 50px 5px 0;
  }
`
const Chip = styled.span<{tag: BikiniEventTag}>`
  display: inline-block;
  border-radius: 10px;
  background: ${(props) => colors.tags[props.tag]};
  width: 15px;
  height: 15px;
  vertical-align: -1px;
  margin-right: 8px;
  @media (max-width: ${BREAKPOINT}px) {
    width: 11px;
    height: 11px;
  }
`

const Modes = styled.div`
  @media (max-width: ${BREAKPOINT}px) {
    display: none;
  }
`
const Mode = styled.button<{active: boolean}>`
  svg {
    fill: ${(props) => (props.active ? colors.white : colors.grey)};
  }
`

export enum FilterTag {
  bikini = 'bikini',
  other = 'other',
  festival = 'festival',
}

export enum FilterType {
  concert = 'concert',
  club = 'club',
}

export enum FilterMode {
  list = 'list',
  squares = 'squares',
  history = 'history',
}

interface Props {
  type: FilterType
  setType: (type: FilterType) => void
  tag: FilterTag
  setTag: (tag: FilterTag) => void
  mode: FilterMode
  setMode: (mode: FilterMode) => void
}

const Filters: React.FC<Props> = ({type, setType, tag, setTag, mode, setMode}) => {
  const [fixed, setFixed] = useState(false)

  const ref = useRef<HTMLDivElement>()

  const goToTop = () => {
    if (fixed && ref.current) {
      window.scrollTo(0, getScroll() - getHeight() + ref.current.getBoundingClientRect().top)
    }
  }

  const handleType = (newType: FilterType) => () => {
    setType(type === newType ? null : newType)
    goToTop()
  }

  const handleTag = (newTag: FilterTag) => () => {
    setTag(tag === newTag ? null : newTag)
    goToTop()
  }

  const handleMode = (newMode: FilterMode) => () => {
    setMode(newMode)
    goToTop()
  }

  const [openMenu, setOpenMenu] = useState(false)
  const handleMenu = () => setOpenMenu(!openMenu)

  const handleHistory = () => setMode(mode === FilterMode.history ? FilterMode.list : FilterMode.history)

  useEffect(() => {
    const handleScroll = () => {
      if (ref.current) {
        setFixed(ref.current.getBoundingClientRect().top < getHeight())
      }
      // setOpenMenu(false)
    }

    document.addEventListener('scroll', handleScroll, {passive: true})
    handleScroll()
    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const key = useClientKey()

  return (
    <Placeholder ref={ref}>
      <Container fixed={fixed}>
        <Dropdown open={openMenu}>
          <TitleButton onClick={handleMenu}>
            Filtre <Down className="down" />
          </TitleButton>
          <HistoryButton onClick={handleHistory} aria-label="Historique">
            <History className={'history' + (mode === FilterMode.history ? ' active' : '')} />
          </HistoryButton>
        </Dropdown>
        <Content open={openMenu}>
          <Types>
            <Prompt>
              Filtres <Chevron />
            </Prompt>
            <Type active={type === FilterType.concert} onClick={handleType(FilterType.concert)}>
              Concerts
            </Type>
            <Type active={type === FilterType.club} onClick={handleType(FilterType.club)}>
              Club
            </Type>
          </Types>
          <Places>
            <Place active={tag === FilterTag.bikini} onClick={handleTag(FilterTag.bikini)}>
              <Chip tag="bikini" />
              Au Bikini
            </Place>
            <Place active={tag === FilterTag.other} onClick={handleTag(FilterTag.other)}>
              <Chip tag="other" />
              Autre salle
            </Place>
            <Place active={tag === FilterTag.festival} onClick={handleTag(FilterTag.festival)}>
              <Chip tag="festival" />
              Curiosités
            </Place>
          </Places>
          <Modes key={key('mode')}>
            <Mode active={mode === FilterMode.list} onClick={handleMode(FilterMode.list)} aria-hidden>
              <List />
            </Mode>
            <Mode active={mode === FilterMode.squares} onClick={handleMode(FilterMode.squares)} aria-hidden>
              <Squares />
            </Mode>
            <Mode active={mode === FilterMode.history} onClick={handleMode(FilterMode.history)} aria-label="Historique">
              <History />
            </Mode>
          </Modes>
        </Content>
      </Container>
    </Placeholder>
  )
}

export default Filters
