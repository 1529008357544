import React, {SVGAttributes} from 'react'

const History: React.FC<SVGAttributes<SVGElement>> = (props) => (
  <svg width={23} height={22} viewBox="0 0 23 22" {...props}>
    <path d="M19.632 3.222C17.46 1.144 14.572 0 11.5 0 9.433 0 7.427.525 5.662 1.522L4.268.189a.696.696 0 0 0-.734-.14.645.645 0 0 0-.416.596v3.084c0 .355.302.644.674.644h3.224a.675.675 0 0 0 .623-.398.624.624 0 0 0-.146-.702l-.843-.806a10.481 10.481 0 0 1 4.85-1.178c5.598 0 10.152 4.356 10.152 9.711s-4.554 9.711-10.152 9.711c-5.598 0-10.152-4.356-10.152-9.711 0-.356-.302-.644-.674-.644-.372 0-.674.288-.674.644 0 2.938 1.196 5.7 3.368 7.778C5.54 20.856 8.428 22 11.5 22s5.96-1.144 8.132-3.222C21.804 16.701 23 13.938 23 11c0-2.938-1.196-5.7-3.368-7.778z" />
    <path d="M15.883 10.432h-3.789V6.807c0-.368-.312-.667-.698-.667-.386 0-.698.299-.698.667V11.1c0 .368.312.667.698.667h4.487c.386 0 .698-.299.698-.667 0-.37-.312-.668-.698-.668z" />
  </svg>
)

export default History
