import styled from 'styled-components'

import {MOBILE_BREAKPOINT} from '../helpers/constants'

export const Title = styled.h1`
  font-size: 3.75rem;
  height: 3rem;
  overflow: hidden;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 1.5rem;
    height: 1.2rem;
  }
`
